import gql from "graphql-tag";
import LocalBookFragments from '@/graphql/LocalBookFragments';

const LOCAL_BOOK_DETAILS_FRAGMENT = LocalBookFragments.localBookDetailsFragment;

export default {
  getLocalBooks: gql`
    query getLocalBooks(
      $page: Int, 
      $itemsPerPage: Int, 
      $simplesearch: String
    ) {
      localBooks(
        page: $page, 
        itemsPerPage: $itemsPerPage,
        simplesearch: $simplesearch
      ) {
        collection {
          ...LocalBookDetails
        }
        paginationInfo { 
          itemsPerPage
          lastPage
          totalCount
          hasNextPage
        }
      }
    }
    ${LOCAL_BOOK_DETAILS_FRAGMENT}
  `,

  getLocalBook: gql`
    query getLocalBook($id: ID!) {
      localBook(id: $id) {
        ...LocalBookDetails
      }
    }
    ${LOCAL_BOOK_DETAILS_FRAGMENT}
  `,
};
