<template>
  <v-container>
    <ApolloQuery
      :query="(gql) => getOrderQuery"
      :variables="{ orderId }"
      :skip="!orderId"
    >
      <template #default="{ result: { data, error }, isLoading }">
        <apollo-error-alert v-if="error" />
        <div v-else-if="isLoading">
          <v-skeleton-loader
            type="card@2"
            width="100%"
            min-height="400px"
          />
        </div>
        <div v-else-if="data">
          <div v-if="data.order.totalPrice > 0">
            <div class="d-flex align-center justify-space-between">
              <h2 class="text-subtitle-1 font-weight-medium">
                {{ $t("checkout.paymentMethod") }}
              </h2>
              <v-checkbox
                v-if="isAdmin || isChecker"
                v-model="isAdminOrder"
                :label="$t('checkout.adminOrder')"
                hide-details
                class="mt-0 text-subtitle-1"
              />
            </div>
            <v-radio-group
              v-model="paymentOption"
              class="mb-5 mt-3"
              hide-details
            >
              <v-row>
                <v-col class="col-6">
                  <PaymentMethodRadio
                    v-model="paymentOption"
                    :selected="paymentOption == paymentTypes.mpesa"
                    :label="$t('checkout.mpesaOrder')"
                    :radio-value="paymentTypes.mpesa"
                  >
                    <template #content>
                      <v-img
                        contain
                        max-width="120"
                        max-height="100%"
                        :src="mpesaLogo"
                      />
                    </template>
                  </PaymentMethodRadio>
                </v-col>
                <v-col class="col-6">
                  <PaymentMethodRadio
                    v-model="paymentOption"
                    :selected="paymentOption == paymentTypes.creditCard"
                    :label="$t('checkout.dpoOrder')"
                    :radio-value="paymentTypes.creditCard"
                  >
                    <template #content>
                      {{ $t("checkout.dpoOrder") }}
                    </template>
                  </PaymentMethodRadio>
                </v-col>
                <v-col
                  v-if="isFeatureEnabled('momoMtnPayment')"
                  class="col-6"
                >
                  <PaymentMethodRadio
                    v-model="paymentOption"
                    :selected="paymentOption == paymentTypes.mtn"
                    :label="$t('checkout.mtnOrder')"
                    :radio-value="paymentTypes.mtn"
                  >
                    <template #content>
                      <v-img
                        contain
                        max-height="62px"
                        :src="mtnLogo"
                      />
                    </template>
                  </PaymentMethodRadio>
                </v-col>
                <v-col
                  v-if="isAdminOrder"
                  class="col-6"
                >
                  <PaymentMethodRadio
                    v-model="paymentOption"
                    :selected="paymentOption == paymentTypes.cashPayment"
                    :label="$t('checkout.cashOrder')"
                    :radio-value="paymentTypes.cashPayment"
                  >
                    <template #content>
                      {{ $t("checkout.cashOrder") }}
                    </template>
                  </PaymentMethodRadio>
                </v-col>
                <v-col
                  v-if="isAdminOrder"
                  class="col-6"
                >
                  <PaymentMethodRadio
                    v-model="paymentOption"
                    :selected="paymentOption == paymentTypes.b2b"
                    :label="$t('checkout.b2b')"
                    :radio-value="paymentTypes.b2b"
                  >
                    <template #content>
                      {{ $t("checkout.b2b") }}
                    </template>
                  </PaymentMethodRadio>
                </v-col>
                <!--
                  TODO: this needs to be reworked as the intention of this form was misunderstood
                  Github Issue: https://github.com/ekitabu-LLC/shop-web/issues/1058
                -->
                <!-- <v-col
                  v-if="isAdminOrder"
                  class="col-6"
                >
                  <PaymentMethodRadio
                    v-model="paymentOption"
                    :selected="paymentOption == paymentTypes.pdq"
                    :label="$t('checkout.b2b')"
                    :radio-value="paymentTypes.pdq"
                  >
                    <template #content>
                      {{ $t("checkout.pdq") }}
                    </template>
                  </PaymentMethodRadio>
                </v-col> -->
                <v-spacer />
              </v-row>
            </v-radio-group>
          </div>
          <no-cost-order
            v-if="data.order.totalPrice == 0"
            :container-classes="formContainerClasses"
          />
          <mobile-payment-form
            v-else-if="paymentOption == paymentTypes.mpesa || paymentOption == paymentTypes.mtn"
            :is-admin-order="isAdminOrder"
            :is-print-order="checkOrderHasPrintBooks(data)"
            :container-classes="formContainerClasses"
            :payment-types="paymentTypes"
            :payment-provider="paymentOption"
          />
          <credit-card
            v-else-if="paymentOption == paymentTypes.creditCard"
            :is-admin-order="isAdminOrder"
            :is-print-order="checkOrderHasPrintBooks(data)"
            :container-classes="formContainerClasses"
          />
          <cash
            v-else-if="paymentOption == paymentTypes.cashPayment"
            :is-print-order="checkOrderHasPrintBooks(data)"
            :container-classes="formContainerClasses"
          />
          <B2bPayment
            v-else-if="paymentOption == paymentTypes.b2b || paymentOption == paymentTypes.pdq"
            :is-print-order="checkOrderHasPrintBooks(data)"
            :container-classes="formContainerClasses"
            :is-pdq="paymentOption == paymentTypes.pdq"
          />
        </div>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import currencyMixins from "@/mixins/currencyMixins";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import NoCostOrder from "./NoCostOrder";
import CreditCard from "./CreditCard";
import AdminCheckMixin from "@/mixins/AdminCheckMixin";
import { FORMATS } from "@/utils/Const";
import gql from "graphql-tag";
import OrderFragments from "@/graphql/OrderFragments";
import Cash from "./Cash";
import PaymentMethodRadio from "./PaymentMethodRadio";
import MobilePaymentForm from "./MobilePaymentForm";
import featureFlags from "@/mixins/featureFlags";
import B2bPayment from "./B2bPayment";

const mpesaLogoPath = require("@/assets/img/mpesa_logo.png");
const mtnLogoPath = "https://shop-cdn.fra1.digitaloceanspaces.com/img/MoMo%20Logo-05.jpg";

const PAYMENT_TYPES = {
  mpesa: "mpesa",
  creditCard: "cc",
  cashPayment: "cash",
  mtn: "mtn",
  b2b: "b2b",
  pdq: "pdq"
};

const ORDER_DETAILS_FRAGMENT = OrderFragments.orderDetailsFragment;

export default {
  name: "CheckoutScreen",
  components: {
    ApolloErrorAlert,
    NoCostOrder,
    CreditCard,
    Cash,
    PaymentMethodRadio,
    MobilePaymentForm,
    B2bPayment
  },
  mixins: [
    currencyMixins,
    AdminCheckMixin,
    featureFlags,
  ],
  data() {
    return {
      paymentOption: PAYMENT_TYPES.mpesa,
      isAdminOrder: false,
    };
  },
  computed: {
    mpesaLogo() {
      return mpesaLogoPath;
    },
    mtnLogo() {
      return mtnLogoPath;
    },
    currentTabComponent: function () {
      return this.radioGroup;
    },
    orderId() {
      return this.$store.state.cart.currentCart;
    },
    getOrderQuery() {
      return gql`
        query GetOrder($orderId: ID!) {
          order(id: $orderId) {
            ...OrderDetails
          }
        }
        ${ORDER_DETAILS_FRAGMENT}
      `;
    },
    paymentTypes() {
      return PAYMENT_TYPES;
    },
    formContainerClasses() {
      return 'border-sm border-lightAccent py-5 px-4 rounded-md elevation-10 mb-4'
    }
  },
  watch: {
    isAdminOrder() {
      if (!this.isAdminOrder) {
        this.paymentOption = PAYMENT_TYPES.mpesa;
      }
    },
  },
  created() {
    if (!this.orderId) {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    checkOrderHasPrintBooks(data) {
      const orderLines = data.order.orderLines?.edges.map(({ node }) => node);
      return !!orderLines.find(
        (orderLine) => orderLine.format == FORMATS.print
      );
    },
  },
};
</script>

<style scoped lang="scss">
.checkout-heading-container {
  border-bottom: 1px solid var(--v-lightAccent-base);
}

.checkout-heading-container h1 {
  font-size: 1.5rem;
}

.checkout-section-container {
  background-color: var(--v-lightestAccent-base);
}

.payment-method-radio {
  font-size: 0.875px;
}

.payment-method-radio-active {
  font-weight: 500;
}

.checkout-section-header {
  font-size: 1.25rem;
  font-weight: 500;
}
</style>
