<template>
  <v-container fluid>
    <v-row>
      <h1>{{ $t("admin.productsDatabase.title") }}</h1>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          class="mr-2"
        >
          {{ $t("admin.productsDatabase.createPublication") }}
        </v-btn>
        <v-btn
          color="secondary"
          class="mr-2"
          @click="showAuthorsDialog = true"
        >
          {{ $t("admin.productsDatabase.viewAuthorDatabase") }}
        </v-btn>
        <v-btn
          color="secondary"
          @click="showPublishersDialog = true"
        >
          {{ $t("admin.productsDatabase.viewPublisherDatabase") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="8"
      >
        <v-text-field
          v-model="searchQuery"
          :label="$t('admin.productsDatabase.searchPlaceholder')"
          prepend-icon="mdi-magnify"
          outlined
          dense
          @input="debounceSearch"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-select
          v-model="sortBy"
          :items="sortItems"
          :label="$t('admin.productsDatabase.sortBy')"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="localBooks"
      :search="search"
      :sort-by.sync="sortBy"
      :loading="loading"
      :server-items-length="totalBooks"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[5, 10, 25, 50]"
      :footer-props="{
        'items-per-page-text': $t('common.itemsPerPage'),
      }"
      class="elevation-1"
      @update:items-per-page="handleItemsPerPageChange"
      @pagination="handlePageChange"
    >
      <!-- Format column -->
      <template #item.format="{ item }">
        <v-chip
          outlined
          small
        >
          {{ item.format._id.toUpperCase() }}
        </v-chip>
      </template>

      <!-- Authors column -->
      <template #item.authors="{ item }">
        {{ formatAuthors(item.bookAuthors) }}
      </template>

      <!-- Status column -->
      <template #item.status="{ item }">
        <v-chip
          :color="statusColor(item.available ? 'published' : 'unlisted')"
          dark
          small
        >
          {{ $t(`bookInfo.${item.available ? "published" : "unlisted"}`) }}
        </v-chip>
      </template>
      <template #item.price="{ item }">
        <Price
          :book-price="item.price || 0"
          :base-currency="item.currency?._id || 'KES'"
          :show-in-base-currency="true"
          :show-free-text="true"
        />
      </template>

      <!-- Action column: View button (or Edit, etc.) -->
      <template #item.action="{ item }">
        <v-btn
          color="primary"
          small
          @click="viewItem(item)"
        >
          {{ $t("common.view") }}
        </v-btn>
      </template>
    </v-data-table>

    <!-- Server Pagination -->
    <v-pagination
      v-model="page"
      :length="lastPage"
      :total-visible="7"
      class="mt-4"
      circle
    />
    <v-dialog
      v-model="showDialog"
      max-width="900px"
    >
      <ProductView
        :book="selectedBook"
        @close="showDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="showAuthorsDialog"
      fullscreen
    >
      <AuthorsDatabase @close="showAuthorsDialog = false" />
    </v-dialog>
    <v-dialog
      v-model="showPublishersDialog"
      fullscreen
    >
      <PublishersDatabase @close="showPublishersDialog = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import LocalBooksQueries from "@/graphql/LocalBookQueries";
import ProductView from "./ProductView.vue";
import Price from "@/components/Price.vue";
import AuthorsDatabase from "./AuthorsDatabase.vue";
import PublishersDatabase from "./PublishersDatabase.vue";

export default {
  components: {
    ProductView,
    Price,
    AuthorsDatabase,
    PublishersDatabase,
  },
  data() {
    return {
      search: "",
      sortBy: "title",
      sortItems: ["Title", "Author", "Format", "Status"],
      page: 1,
      showDialog: false,
      showAuthorsDialog: false,
      showPublishersDialog: false,
      itemsPerPage: 10,
      totalBooks: 0,
      lastPage: 1,
      loading: false,
      localBooks: [],
      headers: [
        { text: this.$t("admin.productsDatabase.titleHeader"), value: "title" },
        {
          text: this.$t("admin.productsDatabase.formatHeader"),
          value: "format",
        },
        {
          text: this.$t("admin.productsDatabase.authorsHeader"),
          value: "authors",
        },
        {
          text: this.$t("admin.productsDatabase.publisherHeader"),
          value: "publisher.name",
        },
        { text: this.$t("admin.productsDatabase.priceHeader"), value: "price" },
        {
          text: this.$t("admin.productsDatabase.statusHeader"),
          value: "status",
        },
        { text: "", value: "action", sortable: false },
      ],
      searchQuery: "",
      searchTimeout: null,
      selectedBook: null,
    };
  },
  created() {
    this.fetchBooks();
  },
  beforeDestroy() {
    clearTimeout(this.searchTimeout);
  },
  methods: {
    viewItem(item) {
      this.selectedBook = item;
      this.showDialog = true;
    },
    statusColor(status) {
      switch (status) {
        case "unlisted":
          return "grey";
        case "scheduled":
          return "blue";
        case "published":
          return "green";
        default:
          return "primary";
      }
    },
    formatAuthors(bookAuthors) {
      if (!bookAuthors) return "";
      return bookAuthors
        .map(
          (author) =>
            `${author.author.name} (${author.role.description || "Author"})`
        )
        .join(", ");
    },
    async handleSearch() {
      this.page = 1; // Reset to first page when searching
      await this.fetchBooks();
    },
    async fetchBooks() {
      this.loading = true;
      try {
        const result = await this.$apollo.query({
          query: LocalBooksQueries.getLocalBooks,
          variables: {
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            simplesearch: this.searchQuery || null,
          },
        });

        const { collection, paginationInfo } = result.data.localBooks;
        this.localBooks = collection;
        this.totalBooks = paginationInfo.totalCount;
        this.lastPage = paginationInfo.lastPage;
        this.itemsPerPage = paginationInfo.itemsPerPage;
      } catch (error) {
        console.error("Error fetching books:", error);
      } finally {
        this.loading = false;
      }
    },
    handlePageChange() {
      this.fetchBooks();
    },
    handleItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.page = 1; // Reset to first page when changing items per page
      this.fetchBooks();
    },
    debounceSearch(event) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.handleSearch();
      }, 600);
    },
  },
};
</script>

<style scoped>
/* Customize spacing, if desired */
.v-btn {
  margin-bottom: 8px;
}
</style>
