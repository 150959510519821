import gql from "graphql-tag";
import AuthorFragments from '@/graphql/AuthorFragments';

const AUTHOR_DETAILS_FRAGMENT = AuthorFragments.authorDetailsFragment;

export default {
  getAuthors: gql`
    query getAuthors($first: Int, $after: String, $name: String) {
      authors(first: $first, after: $after, name: $name) {
        edges {
          node {
            ...AuthorDetails
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
    ${AUTHOR_DETAILS_FRAGMENT}
  `,

  addAuthor: gql`
    mutation addAuthor($name: String!, $bio: String) {
      createAuthor(input: { name: $name, bio: $bio }) {
        author {
          ...AuthorDetails
        }
      }
    }
    ${AUTHOR_DETAILS_FRAGMENT}
  `,

  deleteAuthor: gql`
    mutation deleteAuthor($id: ID!) {
      deleteAuthor(id: $id) {
        author {
          id
        }
      }
    }
  `,
};
