<template>
  <v-card
    height="600px"
    class="d-flex flex-column"
  >
    <v-card-title class="d-flex justify-space-between align-center">
      <span class="text-h5">{{
        $t("admin.productsDatabase.authorsDatabase.title")
      }}</span>
      <v-btn
        icon
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="flex-grow-0">
      <p>{{ $t("admin.productsDatabase.authorsDatabase.description") }}</p>

      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <v-text-field
            v-model="searchQuery"
            :label="
              $t('admin.productsDatabase.authorsDatabase.searchPlaceholder')
            "
            prepend-icon="mdi-magnify"
            outlined
            dense
            @input="debounceSearch"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="flex-grow-1 overflow-y-auto">
      <v-data-table
        :headers="headers"
        :items="authors"
        :loading="loading"
        :items-per-page="-1"
        hide-default-footer
      >
        <!-- Bio column with truncation -->
        <template #item.bio="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="text-truncate"
                style="max-width: 300px"
                v-on="on"
              >
                {{
                  item.bio || $t("admin.productsDatabase.authorsDatabase.noBio")
                }}
              </div>
            </template>
            <span>{{
              item.bio || $t("admin.productsDatabase.authorsDatabase.noBio")
            }}</span>
          </v-tooltip>
        </template>

        <template #item.createdAt="{ item }">
          {{ new Date(item.createdAt).toLocaleDateString() }}
        </template>

        <template #item.action="{ item }">
          <v-btn
            color="error"
            small
            icon
            @click="confirmDeleteAuthor(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <!-- Infinite scroll loading indicator -->
      <div
        v-if="loading && authors.length > 0"
        class="text-center my-2"
      >
        <v-progress-circular indeterminate />
      </div>

      <!-- Intersection observer target -->
      <div ref="loadMoreTrigger" />
    </v-card-text>

    <v-divider />

    <v-card-text class="flex-grow-0">
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="newAuthor.name"
            :label="$t('admin.productsDatabase.authorsDatabase.authorName')"
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="newAuthor.bio"
            :label="$t('admin.productsDatabase.authorsDatabase.authorBio')"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-space-between"
        >
          <v-btn
            color="primary"
            :disabled="!newAuthor.name"
            @click="addNewAuthor"
          >
            {{ $t("admin.productsDatabase.authorsDatabase.addAuthor") }}
          </v-btn>
          <v-btn @click="$emit('close')">
            {{ $t("admin.productsDatabase.authorsDatabase.finish") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Delete confirmation dialog -->
    <v-dialog
      v-model="deleteDialog"
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          {{
            $t("admin.productsDatabase.authorsDatabase.confirmDelete")
          }}
        </v-card-title>
        <v-card-text>
          {{
            $t("admin.productsDatabase.authorsDatabase.confirmDeleteText", {
              name: authorToDelete?.name,
            })
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = false"
          >
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="deleteAuthor"
          >
            {{ $t("common.remove") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import AuthorQueries from "@/graphql/AuthorQueries";
import infiniteScrollMixin from "@/mixins/infiniteScrollMixin";

export default {
  name: "AuthorsDatabase",
  mixins: [infiniteScrollMixin],
  emits: ["close"],

  data() {
    return {
      headers: [
        { text: this.$t("common.name"), value: "name" },
        {
          text: this.$t("admin.productsDatabase.authorsDatabase.bioHeader"),
          value: "bio",
        },
        {
          text: this.$t(
            "admin.productsDatabase.authorsDatabase.dateAddedHeader"
          ),
          value: "createdAt",
        },
        { text: "", value: "action", sortable: false, width: "50px" },
      ],
      newAuthor: {
        name: "",
        bio: "",
      },
      deleteDialog: false,
      authorToDelete: null,
    };
  },

  computed: {
    authors() {
      return this.items; // Map the generic items from mixin to authors for readability
    },
  },

  methods: {
    async fetchItems() {
      this.loading = true;
      try {
        const result = await this.$apollo.query({
          query: AuthorQueries.getAuthors,
          variables: {
            first: this.fetchSize,
            after: this.pageInfo.endCursor,
            name: this.searchQuery || null,
          },
          fetchPolicy: "network-only",
        });

        const { edges, pageInfo, totalCount } = result.data.authors;

        if (edges && edges.length > 0) {
          this.items = [...this.items, ...edges.map((edge) => edge.node)];
        }

        // Update page info
        this.pageInfo = {
          hasNextPage: pageInfo.hasNextPage,
          endCursor: pageInfo.endCursor,
        };
      } catch (error) {
        console.error("Error fetching authors:", error);
      } finally {
        this.loading = false;
      }
    },

    async addNewAuthor() {
      if (!this.newAuthor.name) return;

      this.loading = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: AuthorQueries.addAuthor,
          variables: {
            name: this.newAuthor.name,
            bio: this.newAuthor.bio,
          },
        });

        const newAuthor = result.data.createAuthor.author;

        // Add the new author to the beginning of the list
        this.items = [newAuthor, ...this.items];

        // Reset the form
        this.newAuthor = {
          name: "",
          bio: "",
        };

        // Show success notification
        this.showNotification(
          this.$t("admin.productsDatabase.authorsDatabase.authorAdded")
        );
      } catch (error) {
        console.error("Error adding author:", error);
        this.showNotification(
          error.message || this.$t("errors.general"),
          "error"
        );
      } finally {
        this.loading = false;
      }
    },

    confirmDeleteAuthor(author) {
      this.authorToDelete = author;
      this.deleteDialog = true;
    },

    async deleteAuthor() {
      if (!this.authorToDelete) return;

      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: AuthorQueries.deleteAuthor,
          variables: {
            id: this.authorToDelete.id,
          },
        });

        // Remove the author from the list
        this.items = this.items.filter(
          (author) => author.id !== this.authorToDelete.id
        );

        // Close the dialog
        this.deleteDialog = false;
        this.authorToDelete = null;

        // Show success notification
        this.showNotification(
          this.$t("admin.productsDatabase.authorsDatabase.authorDeleted")
        );
      } catch (error) {
        console.error("Error deleting author:", error);
        this.showNotification(
          error.message || this.$t("errors.general"),
          "error"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-data-table ::v-deep .v-data-table__wrapper {
  overflow-y: visible;
}
</style>
