<template>
  <v-card
    height="600px"
    class="d-flex flex-column"
  >
    <v-card-title class="d-flex justify-space-between align-center">
      <span class="text-h5">{{
        $t("admin.productsDatabase.publishersDatabase.title")
      }}</span>
      <v-btn
        icon
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="flex-grow-0">
      <p>{{ $t("admin.productsDatabase.publishersDatabase.description") }}</p>

      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <v-text-field
            v-model="searchQuery"
            :label="
              $t('admin.productsDatabase.publishersDatabase.searchPlaceholder')
            "
            prepend-icon="mdi-magnify"
            outlined
            dense
            @input="debounceSearch"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="flex-grow-1 overflow-y-auto">
      <v-data-table
        :headers="headers"
        :items="publishers"
        :loading="loading"
        :items-per-page="-1"
        hide-default-footer
      >
        <!-- Description column with truncation -->
        <template #item.description="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="text-truncate"
                style="max-width: 300px"
                v-on="on"
              >
                {{
                  item.description ||
                    $t("admin.productsDatabase.publishersDatabase.noDescription")
                }}
              </div>
            </template>
            <span>{{
              item.description ||
                $t("admin.productsDatabase.publishersDatabase.noDescription")
            }}</span>
          </v-tooltip>
        </template>

        <!-- Date Added column -->
        <template #item.createdAt="{ item }">
          {{ new Date(item.createdAt).toLocaleDateString() }}
        </template>

        <!-- Action column -->
        <template #item.action="{ item }">
          <v-btn
            color="error"
            small
            icon
            @click="confirmDeletePublisher(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <!-- Infinite scroll loading indicator -->
      <div
        v-if="loading && publishers.length > 0"
        class="text-center my-2"
      >
        <v-progress-circular indeterminate />
      </div>

      <!-- Intersection observer target -->
      <div ref="loadMoreTrigger" />
    </v-card-text>

    <v-divider />

    <v-card-text class="flex-grow-0">
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="newPublisher.name"
            :label="
              $t('admin.productsDatabase.publishersDatabase.publisherName')
            "
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="newPublisher.description"
            :label="
              $t(
                'admin.productsDatabase.publishersDatabase.publisherDescription'
              )
            "
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-space-between"
        >
          <v-btn
            color="primary"
            :disabled="!newPublisher.name"
            @click="addNewPublisher"
          >
            {{ $t("admin.productsDatabase.publishersDatabase.addPublisher") }}
          </v-btn>
          <v-btn @click="$emit('close')">
            {{ $t("admin.productsDatabase.publishersDatabase.finish") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Delete confirmation dialog -->
    <v-dialog
      v-model="deleteDialog"
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          {{
            $t("admin.productsDatabase.publishersDatabase.confirmDelete")
          }}
        </v-card-title>
        <v-card-text>
          {{
            $t("admin.productsDatabase.publishersDatabase.confirmDeleteText", {
              name: publisherToDelete?.name,
            })
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = false"
          >
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="deletePublisher"
          >
            {{ $t("common.remove") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import PublisherQueries from "@/graphql/PublisherQueries";
import infiniteScrollMixin from "@/mixins/infiniteScrollMixin";

export default {
  name: "PublishersDatabase",
  mixins: [infiniteScrollMixin],
  emits: ["close"],

  data() {
    return {
      headers: [
        { text: this.$t("common.name"), value: "name" },
        {
          text: this.$t(
            "admin.productsDatabase.publishersDatabase.descriptionHeader"
          ),
          value: "description",
        },
        {
          text: this.$t(
            "admin.productsDatabase.publishersDatabase.dateAddedHeader"
          ),
          value: "createdAt",
        },
        { text: "", value: "action", sortable: false, width: "50px" },
      ],
      newPublisher: {
        name: "",
        description: "",
      },
      deleteDialog: false,
      publisherToDelete: null,
    };
  },

  computed: {
    publishers() {
      return this.items; // Map the generic items from mixin to publishers for readability
    },
  },

  methods: {
    async fetchItems() {
      this.loading = true;
      try {
        const result = await this.$apollo.query({
          query: PublisherQueries.getPublishers,
          variables: {
            first: this.fetchSize,
            after: this.pageInfo.endCursor,
            name: this.searchQuery || null,
          },
          fetchPolicy: "network-only",
        });

        const { edges, pageInfo, totalCount } = result.data.publishers;

        if (edges && edges.length > 0) {
          this.items = [...this.items, ...edges.map((edge) => edge.node)];
        }

        // Update page info
        this.pageInfo = {
          hasNextPage: pageInfo.hasNextPage,
          endCursor: pageInfo.endCursor,
        };
      } catch (error) {
        console.error("Error fetching publishers:", error);
      } finally {
        this.loading = false;
      }
    },

    async addNewPublisher() {
      if (!this.newPublisher.name) return;

      this.loading = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: PublisherQueries.addPublisher,
          variables: {
            name: this.newPublisher.name,
            description: this.newPublisher.description,
          },
        });

        const newPublisher = result.data.createPublisher.publisher;

        // Add the new publisher to the beginning of the list
        this.items = [newPublisher, ...this.items];

        // Reset the form
        this.newPublisher = {
          name: "",
          description: "",
        };

        // Show success notification
        this.showNotification(
          this.$t("admin.productsDatabase.publishersDatabase.publisherAdded")
        );
      } catch (error) {
        console.error("Error adding publisher:", error);
        this.showNotification(
          error.message || this.$t("errors.general"),
          "error"
        );
      } finally {
        this.loading = false;
      }
    },

    confirmDeletePublisher(publisher) {
      this.publisherToDelete = publisher;
      this.deleteDialog = true;
    },

    async deletePublisher() {
      if (!this.publisherToDelete) return;

      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: PublisherQueries.deletePublisher,
          variables: {
            id: this.publisherToDelete.id,
          },
        });

        // Remove the publisher from the list
        this.items = this.items.filter(
          (publisher) => publisher.id !== this.publisherToDelete.id
        );

        // Close the dialog
        this.deleteDialog = false;
        this.publisherToDelete = null;

        // Show success notification
        this.showNotification(
          this.$t("admin.productsDatabase.publishersDatabase.publisherDeleted")
        );
      } catch (error) {
        console.error("Error deleting publisher:", error);
        this.showNotification(
          error.message || this.$t("errors.general"),
          "error"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-data-table ::v-deep .v-data-table__wrapper {
  overflow-y: visible;
}
</style>
