<template>
  <EkitabuExpansionPanel
    accordion
    :value="0"
    :title="$t('bookInfo.bookDetails')"
  >
    <table class="book-details-table">
      <tr v-if="normalizedBook.title">
        <td>{{ $t("bookInfo.title") }}</td>
        <td>{{ decodeHtml(normalizedBook.title) }}</td>
      </tr>
      <tr v-if="normalizedBook.createdAt">
        <td>{{ $t("bookInfo.published") }}</td>
        <td>{{ publicationDate(normalizedBook.createdAt) }}</td>
      </tr>
      <tr v-if="normalizedBook.publisher">
        <td>{{ $t("bookInfo.publisher") }}</td>
        <td>{{ normalizedBook.publisher.name }}</td>
      </tr>
      <tr v-if="normalizedBook.language">
        <td>{{ $t("bookInfo.language") }}</td>
        <td>{{ localizedLanguage }}</td>
      </tr>
      <tr v-if="normalizedBook.format">
        <td>{{ $t("bookInfo.format") }}</td>
        <td>{{ formattedAndTranslated }}</td>
      </tr>
      <tr v-if="normalizedBook.isbn">
        <td>{{ $t("bookInfo.isbn") }}</td>
        <td>{{ normalizedBook.isbn }}</td>
      </tr>
    </table>
  </EkitabuExpansionPanel>
</template>

<script>
import { decodeHtml } from "@/helpers/generalFunctions";
import EkitabuExpansionPanel from "@/components/EkitabuExpansionPanel.vue";
import { isBook } from '@/types/books';
import { convertToLocalBook } from '@/utils/bookConverter';

export default {
  name: "BookDetails",
  components: {
    EkitabuExpansionPanel,
  },
  props: {
    book: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.languageCode || 'en';
    },
    localizedLanguage() {
      if (!this.normalizedBook.language) return '';
      try {
        const displayNames = new Intl.DisplayNames([this.currentLocale], {
          type: 'language'
        });
        return displayNames.of(this.normalizedBook.language);
      } catch (e) {
        console.warn('Language localization failed:', e);
        return this.normalizedBook.language.toUpperCase();
      }
    },
    normalizedBook() {
      return isBook(this.book) ? convertToLocalBook(this.book) : this.book;
    },
    formattedAndTranslated() {
      const format = this.normalizedBook.format;
      const formatId = format?._id || '';
      const cleanFormat = formatId.replace("application/", "");
      const translationKey = `formats.${cleanFormat}`;
      return this.$te(translationKey) ? this.$t(translationKey) : cleanFormat;
    }
  },
  methods: {
    publicationDate(date) {
      if (!date) return '';
      let publicationDate = new Date(date);
      return publicationDate.getFullYear();
    },
    decodeHtml(html) {
      return decodeHtml(html);
    },
  },
};
</script>

<style scoped lang="scss">
table {
  border: none;
  border-collapse: collapse;
}

.book-details-table tr td {
  padding: 7px 14px;
  font-size: 12px;
  line-height: normal;
}

.book-details-table tr:first-of-type td{ 
  padding-top: 14px;
}

.book-details-table tr:last-of-type td {
  padding-bottom: 14px;
}

.book-details-table tr td:first-child {
  color: var(--v-quaternaryText-base);
  font-weight: bold;
  padding-right: 30px;
  border-right: 2px solid var(--v-lightAccent-base);
}

.book-details-table tr td:last-of-type {
  word-break: break-word;
  padding-left: 30px;
}
</style>
