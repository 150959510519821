import gql from "graphql-tag";

export default {
  localBookDetailsFragment: gql`
    fragment LocalBookDetails on LocalBook {
      id
      _id
      title
      available
      description
      publishedOn
      language
      publisher {
        id
        _id
        name
        description
      }
      bookAuthors {
        author {
          id
          _id
          name
          bio
        }
        role {
          id
          _id
          description
        }
      }
      price
      isbn
      thumbnailUrl
      imageUrl
      format {
        id
        _id
        mimeType
        schemaOrgUrl
      }
      currency {
        id
        _id
      }
      availableFrom
      createdAt
      updatedAt
    }
  `,
};
