export const DATE_PRESETS = Object.freeze({
  WEEK: 'week',
  MONTH: 'month',
  YTD: 'ytd'
});

export function handleDatePreset(type) {
  const today = new Date();
  const year = today.getFullYear().toString();
  let endDate = today;
  let startDate = null;

  switch(type) {
    case DATE_PRESETS.WEEK:
      // get the last full week orders from Monday - Sunday
      // find the previous sunday from today;
      // offset will be the remainder of today / 6
      // getDay() uses index 0-6 and day 0 means we don't need an offset
      const endDateOffset = (today.getDay() % 6);
      endDate = new Date(today);
      endDate.setDate(endDate.getDate() - endDateOffset);
      // startDate will be six days before endDate
      startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - 6);
      break;
    case DATE_PRESETS.MONTH:
      // the month so far, from the 1st to current date
      startDate = new Date(year+"/"+(today.getMonth()+1));
      break;
    case DATE_PRESETS.YTD:
      // the year so far, from Jan 1st to current date/time
      startDate = new Date(year);
      break;
  }

  startDate = getSimpleDateString(startDate);
  endDate = getSimpleDateString(endDate);

  return { startDate, endDate };
}

export function getSimpleDateString(date) {
  return date?.toISOString().split('T')[0];
}