<template>
  <DataTableHeaderContainer>
    <v-row class="mb-5">
      <v-col>
        <h1 class="data-table-header">
          {{ title }}
        </h1>
        <h4
          v-if="subtitle"
          class="mt-1"
        >
          {{ subtitle }}
        </h4>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="col-12 col-sm-auto">
        <h4 class="data-table-filter-header mb-1">
          {{ dateLabel }}:
        </h4>
        <v-row
          class="d-flex"
          no-gutters
        >
          <ekitabu-text-input
            id="start-date"
            v-model="startDate"
            label="dd/mm/yyyy"
            class="d-flex flex-column-reverse"
            type="date"
          />
          <span class="mx-3 mt-3">{{ $t('common.to') }}</span>
          <ekitabu-text-input
            id="end-date"
            v-model="endDate"
            label="dd/mm/yyyy"
            class="d-flex flex-column-reverse"
            type="date"
          />
        </v-row>
      </v-col>
      <v-col
        v-if="hasDatePresets"
        class="d-flex align-center justify-end"
        cols="auto"
      >
        <div>
          <v-btn
            v-for="type in datePresetTypes"
            :key="type"
            class="ml-4"
            depressed
            tile
            color="lightAccent"
            @click="getDatePreset(type)"
          >
            {{ $t("admin.datePresets."+type) }}
          </v-btn>
        </div>
      </v-col>
      <v-spacer />
      <v-col
        v-if="hasToggle"
        class="d-flex align-center justify-end"
      >
        <h4 class="data-table-filter-header mr-3">
          {{ toggleLabel }}
        </h4>
        <v-switch
          v-model="toggle"
          class="d-flex flex-column-reverse"
          color="primary"
        />
      </v-col>
      <v-col
        v-if="hasSearch"
        class="mt-auto"
      >
        <h4 class="data-table-filter-header mb-1">
          {{ $t('common.search') }}:
        </h4>
        <ekitabu-text-input
          id="search"
          v-model="searchString"
          :label="searchLabel"
          :placeholder="searchPlaceholder"
          class="d-flex flex-column-reverse"
          type="text"
        />
      </v-col>
    </v-row>
  </DataTableHeaderContainer>
</template>

<script>
import EkitabuTextInput from "@/components/EkitabuTextInput.vue";
import { debounce } from "debounce";
import DataTableHeaderContainer from "@/components/tables/DataTableHeaderContainer.vue";
import { DATE_PRESETS, getSimpleDateString, handleDatePreset } from "@/utils/dateHelper";

const DEBOUNCE_DELAY = 500;

export default {
  name: "DataTableHeader",
  components: {
    EkitabuTextInput,
    DataTableHeaderContainer,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: "",
    },
    searchLabel: {
      type: String,
      required: false,
      default: "",
    },
    dateLabel: {
      type: String,
      required: false,
      default: "",
    },
    hasSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasToggle: {
      type: Boolean,
      required: false,
      default: false,
    },
    toggleValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    toggleLabel: {
      type: String,
      required: false,
      default: "",
    },
    hasDatePresets: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      searchString: "",
      toggle: this.toggleValue,
    };
  },
  computed: {
    datePresetTypes() {
      return Object.freeze([
        DATE_PRESETS.WEEK,
        DATE_PRESETS.MONTH,
        DATE_PRESETS.YTD
      ]);
    }
  },
  watch: {
    startDate() {
      this.$emit("startDate", this.startDate);
    },
    endDate() {
      if (this.endDate === "" || this.endDate === null) {
        this.$emit("endDate", this.endDate);
      }
      else {
        let date = new Date(this.endDate);
        date.setDate(date.getDate() + 1);
        date = getSimpleDateString(date);
        this.$emit("endDate", date);
      }
    },
    searchString() {
      this.debounceInput(this.searchString);
    },
    toggle() {
      this.$emit("toggle", this.toggle);
    },
  },
  methods: {
    debounceInput: debounce(function (val) {
      this.$emit("searchString", this.searchString);
    }, DEBOUNCE_DELAY),
    getDatePreset(type) {
      const dates = handleDatePreset(type);
      this.startDate = dates.startDate;
      this.endDate = dates.endDate;
    }
  },
};
</script>