<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between align-center">
      <span class="text-h5">{{
        $t("admin.productsDatabase.productView.viewProduct")
      }}</span>
      <v-btn
        icon
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="6">
          <p>
            {{ $t("admin.productsDatabase.productView.reviewPublication") }}
          </p>
          <p class="text-body-2">
            {{ $t("admin.productsDatabase.productView.publicationDisplay") }}
          </p>

          <v-row>
            <v-col cols="4">
              <v-img
                :src="bookDetails.image"
                alt="Book Cover"
                class="rounded-lg"
                contain
                height="120"
              />
            </v-col>
            <v-col cols="8">
              <h4>{{ bookDetails.title }}</h4>
              <v-row>
                <v-col>
                  <div class="text-body-2">
                    <template v-for="(author, index) in bookDetails.authors">
                      <span :key="index">
                        {{ author.name }}
                        <span class="font-italic">({{ author.role }})</span>
                        <span v-if="index < bookDetails.authors.length - 1">,
                        </span>
                      </span>
                    </template>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-chip
                    class="mt-2"
                    color="green"
                    outlined
                  >
                    <v-icon left>
                      mdi-calendar
                    </v-icon>
                    Scheduled: {{ bookDetails.scheduledDate }}
                  </v-chip>
                </v-col>
              </v-row>
              <p class="mt-2 font-weight-bold">
                <Price
                  :book-price="bookDetails.price"
                  :base-currency="bookDetails.currency"
                  :show-in-base-currency="true"
                />
              </p>
              <p class="text-body-2">
                {{ bookDetails.description }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col>
              <PrimaryButton @click="editBook">
                {{
                  $t("common.edit")
                }}
              </PrimaryButton>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <BookDetails :book="book" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import BookDetails from "@/components/BookDetails.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import Price from "@/components/Price.vue";
import currencyMixins from "@/mixins/currencyMixins";
import { isLocalBook } from "@/types/books";

export default {
  components: {
    BookDetails,
    PrimaryButton,
    Price,
  },
  mixins: [currencyMixins],
  props: {
    book: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close"],
  data() {
    return {
      bookDetails: {
        image: this.book?.imageUrl || this.book?.thumbnailUrl || "",
        title: this.book?.title || "",
        authors: this.formatAuthors(this.book?.bookAuthors) || [],
        scheduledDate: "Not scheduled",
        price: this.book?.price || 0,
        currency: this.book?.currency?._id || "KES",
        description: this.book?.description || "",
        details: {
          Title: this.book?.title || "",
          Published: this.book?.published || "",
          Publisher: this.book?.publisher?.name || "",
          Language: this.book?.language || "EN",
          Format: this.book?.format?._id?.toUpperCase() || "",
        },
      },
    };
  },
  computed: {
    isLocalBook() {
      return isLocalBook(this.book);
    },
  },
  watch: {
    book: {
      handler(newBook) {
        if (newBook) {
          this.bookDetails = {
            image: newBook.imageUrl || newBook.thumbnailUrl || "",
            title: newBook.title || "",
            authors: this.formatAuthors(newBook.bookAuthors),
            price: newBook.price || 0,
            currency: newBook.currency?._id || "KES",
            description: newBook.description || "",
            scheduledDate: newBook.availableFrom
              ? new Date(newBook.availableFrom).toLocaleDateString()
              : "Not scheduled",
            details: {
              Title: newBook.title || "",
              Published: newBook.createdAt
                ? new Date(newBook.createdAt).getFullYear()
                : "",
              Publisher: newBook.publisher?.name || "",
              Language: newBook.language || "EN",
              Format: newBook.format?._id?.toUpperCase() || "",
            },
          };
        }
      },
      immediate: true,
    },
  },
  methods: {
    editBook() {
      this.$emit("close");
      // Add edit logic here
    },
    statusColor(status) {
      switch (status) {
        case "unlisted":
          return "grey";
        case "scheduled":
          return "blue";
        case "published":
          return "green";
        default:
          return "primary";
      }
    },
    formatAuthors(bookAuthors) {
      if (!this.isLocalBook) {
        return (
          this.book.authors?.map((name) => ({
            name,
            role: "Author",
          })) || []
        );
      }

      if (!bookAuthors) return [];
      return bookAuthors.map((author) => ({
        name: author.author.name,
        role: author.role.description || "Author",
      }));
    },
  },
};
</script>

<style scoped>
.v-img {
  border-radius: 8px;
}
</style>
