<template>
  <div>
    <data-table-header
      :title="$t('admin.orderReport')"
      :date-label="$t('admin.reportDate')"
      :toggle-label="$t('transactionReports.confirmedOrders')"
      has-toggle
      :toggle-value="confirmed"
      has-date-presets
      @startDate="($event) => (startDate = $event)"
      @endDate="($event) => (endDate = $event)"
      @toggle="($event) => (confirmed = $event)"
    />
    <OrdersTable
      :start-date="startDate"
      :end-date="endDate"
      :confirmed="confirmed"
      :custom-headers="headerOverride"
      :from-page="fromRoutes.orderReport"
    />
  </div>
</template>

<script>
import DataTableHeader from "@/components/tables/DataTableHeader.vue";
import OrdersTable from "@/components/tables/OrdersTable.vue";
import { FROM_ROUTES } from "@/utils/Const";

export default {
  name: "OrderReport",
  components: {
    DataTableHeader,
    OrdersTable,
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      confirmed: true,
    };
  },
  computed: {
    fromRoutes() {
      return FROM_ROUTES;
    },
    headerOverride() {
      return [
        {
          text: this.$t("transactionReports.orderId"),
          value: "id",
          class: "column-header",
          sortable: false,
        },
        {
          text: this.$t("transactionReports.date"),
          value: "updatedAt",
          class: "column-header",
        },
        {
          text: this.$t("transactionReports.customer"),
          value: "customerEmail",
          class: "column-header",
        },
        {
          text: this.$t("transactionReports.event"),
          value: "event.name",
          class: "column-header",
        },
        {
          text: this.$t("transactionReports.quantity"),
          value: "quantity",
          class: "column-header",
          sortable: false,
        },
        {
          text: this.$t("transactionReports.totalPrice"),
          value: "totalPriceSet",
          class: "column-header",
        },
      ];
    },
  },
};
</script>
