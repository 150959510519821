<template>
  <v-container>
    <div class="admin-content-container pa-3 mb-6">
      <admin-info />
    </div>
    <div class="admin-content-container pa-3">
      <div class="admin-header-container pb-3 mb-4">
        <h3>{{ $t("admin.reports") }}</h3>
      </div>
      <v-btn
        v-for="item in adminRoutes"
        :key="item.title"
        tile
        elevation="0"
        block
        color="background"
        class="justify-space-between mt-2"
        :to="{ name: item.route }"
      >
        {{ $t('admin.'+item.titlePath) }}
        <v-icon size="17">
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import AdminInfo from '@/views/admin/AdminInfo';

// TODO: This will need to be secured via a backend permission/role check when implemented
//   Github Issue: https://github.com/ekitabu-LLC/shop-api/issues/67

export default {
  name: "AdminDashboard",
  components: {
    AdminInfo
  },
  data() {
    return {
      // TODO: Replace when backend auth0 profile integration is complete
      //  Github Issue: https://github.com/ekitabu-LLC/shop-web/issues/35
      phoneNumber: "123-456-7890",
      password: "**********",
    };
  },
  computed: {
    adminRoutes() {
      return Object.freeze([
        { route: "orderReport", titlePath: "orderReport" },
        { route: "transactionReport", titlePath: "transactionReport" },
        { route: "couponManager", titlePath: "couponManager" },
        { route: "userDashboard", titlePath: "userDashboard" },
        { route: "customerDashboard", titlePath: "customerDashboard" },
        { route: "eventManager", titlePath: "eventManager" },
        { route: "eventDashboard", titlePath: "eventDashboard" },
        { route: "schoolBookListManager", titlePath: "schoolBookListManager" },
        { route: "productsDatabase", titlePath: "productsDatabase.title" },
        { route: "b2bCustomers", titlePath: "b2bCustomers" },
      ]);
    }
  },
};
</script>

<style scoped>
.admin-content-container {
  background-color: var(--v-lightestAccent-base);
}
</style>
