import gql from "graphql-tag";

export default {
  authorDetailsFragment: gql`
    fragment AuthorDetails on Author {
      id
      _id
      name
      bio
      createdAt
      updatedAt
    }
  `,
};
