import gql from "graphql-tag";
import PublisherFragments from '@/graphql/PublisherFragments';

const PUBLISHER_DETAILS_FRAGMENT = PublisherFragments.publisherDetailsFragment;

export default {
  getPublishers: gql`
    query getPublishers($first: Int, $after: String, $name: String) {
      publishers(first: $first, after: $after, name: $name) {
        edges {
          node {
            ...PublisherDetails
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
    ${PUBLISHER_DETAILS_FRAGMENT}
  `,

  addPublisher: gql`
    mutation addPublisher($name: String!, $description: String) {
      createPublisher(input: { name: $name, description: $description }) {
        publisher {
          ...PublisherDetails
        }
      }
    }
    ${PUBLISHER_DETAILS_FRAGMENT}
  `,

  deletePublisher: gql`
    mutation deletePublisher($id: ID!) {
      deletePublisher(id: $id) {
        publisher {
          id
        }
      }
    }
  `,
};
