export default {
  data() {
    return {
      items: [],
      loading: false,
      searchQuery: '',
      searchTimeout: null,
      pageInfo: {
        hasNextPage: false,
        endCursor: null
      },
      observer: null,
      fetchSize: 25,
    };
  },
  
  mounted() {
    this.fetchItems();
    this.setupInfiniteScroll();
  },
  
  beforeDestroy() {
    clearTimeout(this.searchTimeout);
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  
  methods: {
    setupInfiniteScroll() {
      this.observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && this.pageInfo.hasNextPage && !this.loading) {
          this.loadMore();
        }
      }, { threshold: 0.5 });
      
      this.$nextTick(() => {
        if (this.$refs.loadMoreTrigger) {
          this.observer.observe(this.$refs.loadMoreTrigger);
        }
      });
    },
    
    debounceSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.resetAndSearch();
      }, 500);
    },
    
    resetAndSearch() {
      this.items = [];
      this.pageInfo = {
        hasNextPage: false,
        endCursor: null
      };
      this.fetchItems();
    },
    
    async loadMore() {
      if (this.pageInfo.hasNextPage && !this.loading) {
        await this.fetchItems();
      }
    },
    
    // To be implemented by component
    async fetchItems() {
      console.warn('fetchItems method should be implemented by the component');
    },
    
    // Helper for showing notifications
    showNotification(text, color = 'success') {
      this.$store.dispatch('snackbar/showSnackbar', { text, color });
    }
  }
};
