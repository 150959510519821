<template>
  <div>
    <v-btn
      tile
      depressed
      color="secondary"
      v-bind="$attrs"
      @click="downloadTransactionReports()"
    >
      {{ label }}
    </v-btn>
  </div>
</template>

<script>
import i18n from '@/i18n';
import { getProviderDetails } from '@/utils/PaymentProviderHelper';
import { formatDate } from '@/helpers/generalFunctions';
import { getFormat } from '@/utils/BookFormatHelper';

export default {
  name: "DownloadTransactionReportsButton",
  props: {
    transactionData: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: i18n.t('admin.downloadPage'),
    },
    page: {
      type: Number,
      default: null,
    },
  },
  computed: {
    fileName() {
      const date = new Date().toLocaleDateString().replaceAll('/', '_')
      if (this.page === null) {
        return "transaction_report_"+date+".csv";
      }
      return "transaction_report_page"+this.page+"_"+date+".csv";
    },
  },
  methods: {
    downloadTransactionReports() {
      // create a row for each transaction
      const rows = [];
      const hasOrderFulfillments = this.transactionData?.find((order) => order?.forOrder?.orderFulfillment);
      const hasAdminOrders = this.transactionData?.find((order) => order?.forOrder?.adminOrder);

      //create a field_name for each field in the transaction
      const rowHeaders = [
        this.$t('csvHeaders.transactionId'),
        this.$t('csvHeaders.orderId'),
        this.$t('csvHeaders.orderDate'),
        this.$t('csvHeaders.bookId'),
        this.$t('csvHeaders.bookName'),
        this.$t('csvHeaders.publisher'),
        this.$t('csvHeaders.format'),
        this.$t('csvHeaders.customerId'),
        this.$t('csvHeaders.region'),
        this.$t('csvHeaders.taxRegion'),
        this.$t('csvHeaders.taxRate'),
        this.$t('csvHeaders.baseCurrency'),
        this.$t('csvHeaders.basePrice'),
        this.$t('csvHeaders.quantity'),
        this.$t('csvHeaders.convertedCurrency'),
        this.$t('csvHeaders.convertedBasePrice'),
        this.$t('csvHeaders.finalPricePaid'),
        this.$t('csvHeaders.taxPaid'),
        this.$t('csvHeaders.discountReceived'),
        this.$t('csvHeaders.couponCodeUsed'),
        this.$t('csvHeaders.provider'),
        this.$t('csvHeaders.providerTransactionId'),
        this.$t('csvHeaders.event'),

        // Print Book Order Status
        ...(
          hasOrderFulfillments ? [
            this.$t('csvHeaders.fulfilledAt'),
            this.$t('csvHeaders.deliveryMethod'),
            this.$t('csvHeaders.shippingFee'),
          ] : []
        ),

        // Admin Order Report
        this.$t('csvHeaders.isAdminOrder'),
        ...(
          hasAdminOrders ? [
            this.$t('csvHeaders.forUserName'),
            this.$t('csvHeaders.forUserPhone'),
            this.$t('csvHeaders.forUserEmail'),
            this.$t('csvHeaders.dateClaimed'),
            this.$t('csvHeaders.adminId'),
          ] : []
        ),
      ];
      rows.push(rowHeaders);

      // Then set the desired columns in that row as comma seperated values
      // Then push that row into the rows array
      var dateOptions = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};

      this.transactionData.forEach(item => {
        let dateString = new Date(item.forOrder.updatedAt);
        dateString = dateString.toLocaleDateString('en-us', dateOptions);

        const providerDetails = getProviderDetails(item.forOrder);

        const row = [
          item._id,
          item.forOrder._id,
          "\""+dateString+"\"",
          item.productId,
          "\""+item.name+"\"",
          "\""+item.publisher+"\"",
          item?.format ? getFormat(item.format) :  this.$t('common.none'),
          item.forOrder.customerEmail ? "\""+item.forOrder.customerEmail+"\"" : "\""+item.forOrder.customerId+"\"",
          item.forOrder.customerLocale ?? this.$t('common.none'),
          item.forOrder?.taxRate?._id,
          item.forOrder?.taxRate?.rate,
          item.baseCurrency._id,
          item.basePrice,
          item.quantity,
          item.forOrder.currency._id,
          item.preTaxPriceSet,
          item.priceSet,
          item.priceSet - (item.preTaxPriceSet - item.discountSet),
          item.discountSet ?? 0,
          item.forOrder?.orderCoupon?.code ?? this.$t('common.none'),
          providerDetails.provider ?? this.$t('common.unavailable'),
          providerDetails.transactionId ?? this.$t('common.unavailable'),
          item.forOrder?.event?.name ?? this.$t('common.none'),
          // Print Book Order Status
          ...( hasOrderFulfillments && item?.forOrder?.orderFulfillment ? [
              item.forOrder.orderFulfillment.fulfilledAt ? "\""+formatDate(item.forOrder.orderFulfillment.fulfilledAt)+"\"" : this.$t('common.none'),
              item.forOrder?.shippingFeeSet ? this.$t('checkout.delivery.deliveryMethods.delivery') :
                item.forOrder.orderFulfillment.school ? this.$t('checkout.delivery.deliveryMethods.school') :
                this.$t('checkout.delivery.deliveryMethods.pickup'),
              item.forOrder?.shippingFeeSet ?? 0,
            ]
            : hasOrderFulfillments ? [
              this.$t('common.none'),
              this.$t('common.none'),
              this.$t('common.none'),
            ] : []
          ),
          // Admin Order details
          item.forOrder?.adminOrder ? this.$t('common.yes') : this.$t('common.no'),
          ...( hasAdminOrders ? [
            item.forOrder?.adminOrder?.forUserFullName ?? this.$t('common.none'),
            item.forOrder?.adminOrder?.forUserPhone ?? this.$t('common.none'),
            item.forOrder?.adminOrder?.forUserEmail ?? this.$t('common.none'),
            item.forOrder?.adminOrder?.claimedAt ? "\""+formatDate(item.forOrder.adminOrder.claimedAt)+"\"" : this.$t('common.none'),
            item.forOrder?.adminOrder?.adminUserId ?? this.$t('common.none'),
          ] : [] ),
        ];
        rows.push(row);
      });

      // Then join the rows array into a string with each row seperated by a new line
      const csvContent = "data:text/csv;charset=utf-8,"+rows.join("\n");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", this.fileName);
      link.click();
    },
  }
}
</script>