import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import BookInfo from "@/views/BookInfo.vue";
import Contact from "@/views/Contact.vue";
import Bookshelf from "@/views/Bookshelf";
import UserProfile from "@/views/user/Profile";
import EditUserProfile from "@/views/user/EditProfile";
import Cart from "@/views/checkout/Cart/Cart";
import Checkout from "@/views/checkout/Checkout";
import UserBookList from "@/views/UserBookList";
import OrderComplete from "@/views/checkout/OrderComplete";
import { authGuard, authAdminRoleGuard } from "@/auth/authGuard";
import OrderPending from "@/views/checkout/OrderPending";
import FilteredBookList from "@/views/FilteredBookList";
import VerificationPending from "@/views/VerificationPending";
import PublisherBookList from "@/views/PublisherBookList";
import i18n from "@/i18n";
import Admin from "@/views/admin/Admin";
import AdminDashboard from "@/views/admin/AdminDashboard";
import TransactionReport from "@/views/admin/TransactionReport";
import CouponManager from "@/views/admin/CouponManager";
import SearchResults from "@/views/SearchResults";
import RefundPolicy from "@/views/RefundPolicy";
import UserDashboard from "@/views/admin/UserDashboard";
import EventManager from "@/views/admin/EventManager";
import CustomerDashboard from "@/views/admin/CustomerDashboard";
import Customer from "@/views/admin/Customer";
import OrderOverview from "@/views/admin/OrderOverview";
import EventDashboard from "@/views/admin/EventDashboard";
import EventOverview from "@/views/admin/EventOverview.vue";
import SchoolBookListManager from "@/views/admin/SchoolBookListManager";
import SchoolBookLists from "@/views/SchoolBookLists";
import HideMobileNav from "@/views/HideMobileNav";
import B2bCustomers from "@/views/admin/B2bCustomers.vue";
import ProductsDatabase from "@/views/admin/ProductsDatabase/ProductsDatabase.vue";
import OrderReport from "@/views/admin/OrderReport.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/hide-mobile-nav/:hide",
    name: "hideMobileNav",
    component: HideMobileNav,
  },
  {
    path: "/filtered-book-list/:id/:tagName",
    name: "filteredBookList",
    component: FilteredBookList,
  },
  {
    path: "/bookshelf/",
    name: "bookshelf",
    component: Bookshelf,
    beforeEnter: authGuard,
  },
  {
    path: "/user-book-list/:id",
    name: "userBookList",
    component: UserBookList,
  },
  {
    path: "/book/:id/:fromEmail?",
    name: "book",
    component: BookInfo,
    meta: {
      showReturnBar: true,
    },
  },
  {
    path: "/cart/",
    name: "cart",
    component: Cart,
    meta: {
      showReturnBar: true,
      returnBarToRouteName: "home",
      returnBarTextLoc: "checkout.backToStore",
    },
  },
  {
    path: "/checkout/",
    name: "checkout",
    component: Checkout,
    meta: {
      showReturnBar: true,
      returnBarToRouteName: "home",
      returnBarTextLoc: "checkout.backToStore",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/confirmed/:id",
    name: "confirmed",
    component: OrderComplete,
    meta: {
      showReturnBar: true,
      returnBarToRouteName: "home",
      returnBarTextLoc: "checkout.backToStore",
    },
    beforeEnter: authGuard,
  },
  {
    path: "/contact/",
    name: "contact",
    component: Contact,
  },
  {
    path: "/profile/:snackbar?",
    name: "userProfile",
    component: UserProfile,
    beforeEnter: authGuard,
  },
  {
    path: "/edit-profile",
    name: "editUserProfile",
    component: EditUserProfile,
    beforeEnter: authGuard,
  },
  {
    path: "/order-pending/:id/:safaricomMpesaRequestId?/:momoMtnRequestId?",
    name: "orderPending",
    component: OrderPending,
    beforeEnter: authGuard,
    meta: {
      showReturnBar: true,
    },
  },
  {
    path: "/publisher-book-list/:id",
    name: "publisherBookList",
    component: PublisherBookList,
    props: true,
  },
  {
    path: "/verification-pending/",
    name: "verificationPending",
    component: VerificationPending,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/",
    component: Admin,
    beforeEnter: authAdminRoleGuard,
    redirect: { name: "adminDashboard" },
    children: [
      {
        path: "admin-dashboard",
        name: "adminDashboard",
        component: AdminDashboard,
      },
      {
        path: "order-report",
        name: "orderReport",
        component: OrderReport,
      },
      {
        path: "transaction-report",
        name: "transactionReport",
        component: TransactionReport,
      },
      {
        path: "coupon-manager",
        name: "couponManager",
        component: CouponManager,
      },
      {
        path: "user-dashboard",
        name: "userDashboard",
        component: UserDashboard,
      },
      {
        path: "customer-dashboard",
        name: "customerDashboard",
        component: CustomerDashboard,
        meta: {
          breadCrumbs() {
            return [
              {
                text: i18n.t("routes.adminDashboard"),
                to: { name: "adminDashboard" },
                disabled: false,
              },
              {
                text: i18n.t("routes.customerDashboard"),
                to: { name: "customerDashboard" },
                disabled: false,
              },
            ];
          },
        },
      },
      {
        path: "customer/:id/:customerName?",
        name: "customer",
        component: Customer,
        meta: {
          breadCrumbs(route) {
            const id = route.params.id;
            const customerName = route.params.customerName;
            const fromOrderReport = route.params.fromOrderReport
            return [
              {
                text: i18n.t("routes.adminDashboard"),
                to: { name: "adminDashboard" },
                disabled: false,
              },
              (fromOrderReport ?
                { 
                  text: i18n.t("routes.orderReport"),
                  to: { name: "orderReport" },
                  disabled: false,
                } :
                {
                  text: i18n.t("routes.customerDashboard"),
                  to: { name: "customerDashboard" },
                  disabled: false,
                }
              ),
              {
                text: customerName ?? i18n.t("routes.customer"),
                to: {
                  name: "customer",
                  params: { id: id, customerName: customerName },
                },
                params: { id: id, customerName: customerName },
                disabled: false,
              },
            ];
          },
        },
      },
      {
        path: "order-overview/:orderId/:customerId?/:customerName?/:eventId?/:eventName?",
        name: "orderOverview",
        component: OrderOverview,
        meta: {
          breadCrumbs(route) {
            const orderId = route.params.orderId;
            const customerId = route.params.customerId;
            const customerName = route.params.customerName;
            const eventId = route.params.eventId;
            const eventName = route.params.eventName;
            const fromOrderReport = route.params.fromOrderReport;
            return [
              {
                text: i18n.t("routes.adminDashboard"),
                to: { name: "adminDashboard" },
                disabled: false,
              },
              ...(fromOrderReport
                ? [
                    {
                      text: i18n.t("routes.orderReport"),
                      to: { name: "orderReport" },
                      disabled: false,
                    },
                  ]
                : eventId
                ? [
                    {
                      text: i18n.t("routes.eventDashboard"),
                      to: { name: "eventDashboard" },
                      disabled: false,
                    },
                    {
                      text: eventName ?? i18n.t("routes.eventOverview"),
                      to: {
                        name: "eventOverview",
                        params: { id: eventId, eventName: eventName },
                      },
                      params: { id: eventId, eventName: eventName },
                      disabled: false,
                    },
                  ]
                : customerId
                ? [
                    {
                      text: i18n.t("routes.customerDashboard"),
                      to: { name: "customerDashboard" },
                      disabled: false,
                    },
                    {
                      text: customerName ?? i18n.t("routes.customer"),
                      to: {
                        name: "customer",
                        params: { id: customerId, customerName: customerName },
                      },
                      params: { id: customerId, customerName: customerName },
                      disabled: false,
                    },
                  ]
                : []),
              {
                text: i18n.t("common.order") + ": " + orderId,
                to: {
                  name: "orderOverview",
                  params: {
                    id: orderId,
                    customerId: customerId,
                    customerName: customerName,
                  },
                },
                params: {
                  id: orderId,
                  customerId: customerId,
                  customerName: customerName,
                },
                disabled: false,
              },
            ];
          },
        },
      },
      {
        path: "event-manager",
        name: "eventManager",
        component: EventManager,
      },
      {
        path: "event-dashboard",
        name: "eventDashboard",
        component: EventDashboard,
        meta: {
          breadCrumbs() {
            return [
              {
                text: i18n.t("routes.adminDashboard"),
                to: { name: "adminDashboard" },
                disabled: false,
              },
              {
                text: i18n.t("routes.eventDashboard"),
                to: { name: "eventDashboard" },
                disabled: false,
              },
            ];
          },
        },
      },
      {
        path: "event-overview/:id/:eventName?",
        name: "eventOverview",
        component: EventOverview,
        meta: {
          breadCrumbs(route) {
            const id = route.params.id;
            const eventName = route.params.eventName;
            return [
              {
                text: i18n.t("routes.adminDashboard"),
                to: { name: "adminDashboard" },
                disabled: false,
              },
              {
                text: i18n.t("routes.eventDashboard"),
                to: { name: "eventDashboard" },
                disabled: false,
              },
              {
                text: eventName ?? i18n.t("routes.eventOverview"),
                to: {
                  name: "eventOverview",
                  params: { id: id, eventName: eventName },
                },
                params: { id: id, eventName: eventName },
                disabled: false,
              },
            ];
          },
        },
      },
      {
        path: "school-book-list-manager",
        name: "schoolBookListManager",
        component: SchoolBookListManager,
      },
      {
        path: "products-database",
        name: "productsDatabase",
        component: ProductsDatabase,
      },
    ],
  },
  {
    path: "/search/:searchTerm/",
    name: "searchResults",
    component: SearchResults,
    meta: {
      showReturnBar: true,
      returnBarToRouteName: "home",
      returnBarTextLoc: "checkout.backToStore",
    },
  },
  {
    path: "/refund-policy/",
    name: "refundPolicy",
    component: RefundPolicy,
  },
  {
    path: "/school-book-lists/",
    name: "schoolBookLists",
    component: SchoolBookLists,
  },
  {
    path: "/book-list/:id",
    name: "bookList",
    component: UserBookList,
    meta: {
      showReturnBar: true,
    },
  },
  {
    path: "/b2b-customers/",
    name: "b2bCustomers",
    component: B2bCustomers,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute && toRoute.name
      ? i18n.t("common.siteTitle", { route: i18n.t("routes." + toRoute.name) })
      : i18n.t("common.siteTitle", { route: i18n.t("routes.home") });

  next();
});

export default router;
