import { isBook } from '@/types/books';

export const convertToLocalBook = (book) => {
  if (!isBook(book)) return book;
  
  return {
    id: book.id,
    _id: book._id,
    title: book.title,
    available: true,
    description: book.description,
    publisher: {
      id: null,
      _id: null,
      name: book.publisher || '',
      description: ''
    },
    language: book.language,
    bookAuthors: book.authors?.map(name => ({
      author: {
        id: null,
        _id: null,
        name,
        bio: ''
      },
      role: {
        id: null,
        _id: null,
        description: 'Author'
      }
    })) || [],
    price: book.price,
    thumbnailUrl: book.thumbnailUrl,
    imageUrl: book.imageUrl,
    format: {
      id: null,
      _id: book.format || '',
      mimeType: book.format || '',
      schemaOrgUrl: null
    },
    currency: book.currency,
    createdAt: book.modified || new Date().toISOString(),
    updatedAt: book.modified || new Date().toISOString(),
    publishedOn: book.modified || new Date().toISOString(),
    availableFrom: null,
    isbn: book.isbn || '',
  };
};
