/**
 * @typedef {Object} LocalBook
 * @property {string} id
 * @property {string} _id
 * @property {string} title
 * @property {boolean} available
 * @property {string} description
 * @property {Object} publisher
 * @property {string} publisher.id
 * @property {string} publisher._id
 * @property {string} publisher.name
 * @property {string} publisher.description
 * @property {Array<BookAuthor>} bookAuthors
 * @property {number} price
 * @property {string} thumbnailUrl
 * @property {string} imageUrl
 * @property {Object} format
 * @property {string} format._id
 * @property {string} format.mimeType
 * @property {Object} currency
 */

/**
 * @typedef {Object} BookAuthor
 * @property {Object} author
 * @property {string} author.id
 * @property {string} author._id
 * @property {string} author.name
 * @property {string} author.bio
 * @property {Object} role
 * @property {string} role.description
 */

export const isLocalBook = (book) => {
  return book && typeof book.available !== 'undefined' && Array.isArray(book.bookAuthors);
};

export const isBook = (book) => {
  return book && Array.isArray(book.authors) && typeof book.format === 'string';
};