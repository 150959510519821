
import gql from "graphql-tag";

export default {
  publisherDetailsFragment: gql`
    fragment PublisherDetails on Publisher {
      id
      _id
      name
      description
      createdAt
      updatedAt
    }
  `,
};